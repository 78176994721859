import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAuth } from "react-oidc-context";
import AuthenticationService from '#/lib/AuthenticationService';
import FrontOffice from '#/frontoffice/FrontOffice';

const ReactSwal = withReactContent(Swal);

const AuthenticatedRoute = (props) => {

	const auth = useAuth();
	AuthenticationService.init(auth);
	if (AuthenticationService.isUserLoggedIn()) {
		if(AuthenticationService.haveRolesPermssions(props.roles)){

			return <Route exact={props.exact} path={props.path}>{props.component}</Route>
		}
		else {
			ReactSwal.fire({
				title: "<h2>Non si hanno i ruoli necessari per accedere</h2>",
				showCancelButton: false,
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'OK'
			}).then(value => {
				window.history.length ? window.history.back(): window.location.href = "/frontoffice";
			});
			return null;
		}
	}else{
		auth.signinRedirect();
		return null;
	}
}

export default AuthenticatedRoute;
